exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-bot-js": () => import("./../../../src/pages/about/bot.js" /* webpackChunkName: "component---src-pages-about-bot-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-optout-js": () => import("./../../../src/pages/optout.js" /* webpackChunkName: "component---src-pages-optout-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rituals-index-js": () => import("./../../../src/pages/rituals/index.js" /* webpackChunkName: "component---src-pages-rituals-index-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tarot-about-js": () => import("./../../../src/pages/tarot/about.js" /* webpackChunkName: "component---src-pages-tarot-about-js" */),
  "component---src-pages-tarot-cards-[id]-js": () => import("./../../../src/pages/tarot/cards/[id].js" /* webpackChunkName: "component---src-pages-tarot-cards-[id]-js" */),
  "component---src-pages-tarot-cards-index-js": () => import("./../../../src/pages/tarot/cards/index.js" /* webpackChunkName: "component---src-pages-tarot-cards-index-js" */),
  "component---src-pages-tarot-index-js": () => import("./../../../src/pages/tarot/index.js" /* webpackChunkName: "component---src-pages-tarot-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

